import React from 'react'
import styled from 'styled-components'

const Img = styled.div`
  background-color: transparent;
`

const Image = url => {
  const link = `url(${url.imgUrl})`
  return (
    <div>
      <Img
        style={{
          backgroundImage: link,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          height: url.height ? url.height : '200px',
          width: url.width ? url.width : '100%',
        }}
      ></Img>
    </div>
  )
}
export default Image
