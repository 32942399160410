import React from 'react'
import styled from 'styled-components'
import Image from '../components/image'

const Projects = ({
  data: {
    allMdx: { edges: data },
  },
}) => {
  return (
    <ProjectLayout>
      <div style={{ textAlign: 'center' }}>Under development!</div>
    </ProjectLayout>
  )
}

export const ProjectLayout = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #fcdd8f;
`
export const ProjectsQuery = graphql`
  query ProjectsQuery {
    allMdx {
      edges {
        node {
          frontmatter {
            title
            specification
            img
          }
          excerpt(pruneLength: 200)
          timeToRead
        }
      }
    }
  }
`
export default Projects
